import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { LandingPageV2 } from '@talentinc/gatsby-theme-ecom/types/landingPageV2'
import { PageContextType, PageDataContextType } from '@talentinc/gatsby-theme-ecom/types/page'
import Layout from '@talentinc/gatsby-theme-ecom/components/Layout'
import HeaderV2 from '@talentinc/gatsby-theme-ecom/components/HeaderV2/HeaderV2'
import Footer from '@talentinc/gatsby-theme-ecom/components/Footer/Footer'
import BaseLandingPageV2Template from '@talentinc/gatsby-theme-ecom/components/Templates/LandingPageV2'
import SEO from '@talentinc/gatsby-theme-ecom/components/SEO'
import { useLogFrontEndVisit } from '@talentinc/gatsby-theme-ecom/hooks/useLogFrontEndVisit'

import '../../../common-styles/match.css'
import '../../../common-styles/pricing-test.css'

type Props = PageProps<{ landingPageV2: LandingPageV2 } & PageDataContextType>

const LandingPageV2Template = (props: Props) => {
  const { landingPageV2, ...pageData } = props.data
  const { noIndex } = props.pageContext as PageContextType

  useLogFrontEndVisit()

  return (
    <Layout pageTitle={landingPageV2.title} pageData={pageData}>
      <SEO landingPage={landingPageV2} noIndex={noIndex}/>
      <HeaderV2 />
      <BaseLandingPageV2Template landingPage={landingPageV2} />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query LandingPagesV2BySlugAndBrand($slug: String!, $brandName: String!) {
    landingPageV2: contentfulLandingPageV2(
      slug: { eq: $slug }
      node_locale: { eq: "fr" }
      brand: { elemMatch: { name: { eq: $brandName } } }
    ) {
      ...LandingPageV2
    }
    brand: contentfulBrand(name: { eq: $brandName }) {
      ...Brand
    }
    headerV2: contentfulHeaderV2(
      brand: {
        elemMatch: { name: { eq: $brandName }, node_locale: { eq: "fr" } }
      }
    ) {
      ...HeaderV2
    }
    footer: contentfulFooter(
      brand: { name: { eq: $brandName }, node_locale: { eq: "fr" } }
    ) {
      ...Footer
    }
    locales: allLocale(filter: { language: { eq: "fr" } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default LandingPageV2Template
